<template>
  <div class="bg login-route">
    <div
      style="
        font-weight: 600;
        color: #bcccff;
        font-weight: bolder;
        position: absolute;
        top: 43px;
        left: 50px;
        font-size: 30px;
        display: flex;
        jusitfy-content: space-between;
        align-items: center;
      "
    >
      <!-- src="@/assets/logo-1.png" -->
      <!-- <img :src="srcUrl" v-if="srcUrl"  style="margin-right: 18px;width:155px;height:59px" /> -->
      <img :src="srcUrl" v-if="srcUrl" style="margin-right: 18px" />
      {{ loginTtile }}
    </div>
    <div style="position: absolute; left: 70px; top: 105px">
      <img src="@/assets/主题.png" style="width: 900px; height: 711px" />
    </div>
    <div class="center">
      <!-- <img class="img" src="~@/assets/login_layout.png" alt /> -->
      <div style="position: absolute; top: 0; left: 0">
        <img src="~@/assets/直角1.png" alt />
      </div>
      <div style="position: absolute; top: 0; right: 0">
        <img src="~@/assets/直角2.png" alt />
      </div>
      <div style="position: absolute; bottom: -4px; right: 0">
        <img src="~@/assets/直角3.png" alt />
      </div>
      <div style="position: absolute; bottom: -4px; left: 0">
        <img src="~@/assets/直角4.png" alt />
      </div>
      <div class="form-wrap">
        <div class="title">用&nbsp;&nbsp;户&nbsp;&nbsp;登&nbsp;&nbsp;录</div>
        <!-- <div>
          <a-tabs v-model="tabKey">
            <a-tab-pane
              tab="账号登录"
              key="1"
            ></a-tab-pane>
            <a-tab-pane
              tab="手机登录"
              key="2"
              forceRender
            ></a-tab-pane>
          </a-tabs>
        </div>-->
        <el-form ref="formLogin" :model="form" class="validate-form">
          <div class="mt">
            <!-- <a-form-item class="login-item">
              <a-select
                size="large"
                :getPopupContainer="triggerNode => triggerNode.parentNode"
                v-decorator="['usertype',{initialValue:'1'}]"
              >
                <a-select-option value="1">我是计量所</a-select-option>
                <a-select-option value="2">我是企业</a-select-option>
              </a-select>
              </a-input>
            </a-form-item>-->
          </div>
          <div>
            <el-form-item class="login-item" style="margin-bottom: 30px">
              <el-input
                class="input"
                size="large"
                type="text"
                placeholder="请输入用户名"
                v-model="form.loginAccount"
                @keyup.enter.native="handleSubmit()"
                style=""
              >
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="login-item psd">
              <el-input
                class="input"
                size="large"
                @pressEnter="handleSubmit"
                type="password"
                placeholder="请输入密码"
                v-model="form.password"
                @keyup.enter.native="handleSubmit()"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-checkbox
              v-model="checked"
              :fill="fillcolor"
              :text-color="fillcolor"
              ><span style="color: white; opacity: 0.7"
                >记住密码</span
              ></el-checkbox
            >
          </div>
          <!-- <div
            class="inputs"
            v-else
          >
            <a-form-item class="login-item">
              <a-input
                class="input"
                size="large"
                type="text"
                placeholder="请输入手机号"
                v-decorator="[
                'mobile',
                {validateTrigger:'blur',rules: [{ validator: validatePhone}]}
              ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item class="login-item validate-code">
              <a-input
                class="input"
                size="large"
                @pressEnter="handleSubmit"
                type="text"
                placeholder="请输入验证码"
                v-decorator="[
                'verifyCode',
                {validateTrigger:'blur',rules: [{ required: true, message: '请输入验证码' }]}
              ]"
              >
              </a-input>
              <div class="code-wrap">
                <a-button
                  @click="sendVerifyCode"
                  :loading="smallBtnLoading"
                  :disabled="ifShowCount"
                >{{btnText}}</a-button>
              </div>
            </a-form-item>
          </div>-->
          <!-- <div
            class="remember-wrap"
            v-if="tabKey==='1'"
          >
            <a-form-item>
              <a-checkbox-group
                v-decorator="['ifRemember']"
                style="width: 100%;"
              >
                <a-checkbox value="1">
                  记住密码
                </a-checkbox>
              </a-checkbox-group>
              <div
                class="forget"
                v-if="tabKey==='1'"
              ><span @click="jumpToResetPassword">忘记密码</span></div>
            </a-form-item>
          </div>-->
          <div style="margin-top: 40px">
            <el-form-item>
              <el-button
                size="large"
                type="primary"
                @click="handleSubmit"
                class="login-button"
                :loading="loginBtn"
                :disabled="loginBtn"
                style="background: #3f6df8"
                >登&nbsp;&nbsp;&nbsp;&nbsp;录</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="copyright">
      <div
        style="
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 10px;
          opacity: 0.6;
        "
      >
        <!-- Copyright 2021 Hub Energy Technology Co., Ltd -->
        {{ copyRight[0] }}
      </div>
      <div
        style="font-size: 14px; font-weight: 400; color: #ffffff; opacity: 0.6"
      >
        <!-- 版权所有哈博能源科技（苏州）有限公司 -->
        {{ copyRight[1] }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import {
  getLoginSrc,
  getLoginTitle,
  getLoginCopyRight,
  loginApi,
  getMenu,
  getProjects,
  setProject,
} from "@/request/apiLg";
// import { loginApi } from "@/request/apiInfo";
import * as utils from "@/utils/utils";
// import axios from "axios";
export default {
  data() {
    return {
      loginTtile: "菱感能源智慧平台",
      copyRight: "",
      fillcolor: "#3F6DF8",
      srcUrl: "",
      checked: false,
      timer: null,
      // validatePhone,
      tabKey: "1",
      count: 60,
      ifShowCount: false,
      smallBtnLoading: false,
      loginBtn: false,
      // form: this.$form.createForm(this)
      form: {
        loginAccount: "",
        password: "",
      },
    };
  },
  mounted() {
    const account = utils.getAccount();
    if (account && account.ifRemember) {
      // this.form.setFieldsValue({
      //   loginAccount: account.loginAccount,
      //   password: account.password,
      // });
      this.form.loginAccount = account.username;
      this.form.password = account.password;
      this.checked = true;
    }
    getLoginSrc().then((res) => {
      this.srcUrl = res.data.msg;
      console.log("this.srcUrl", this.srcUrl);
    });
    getLoginTitle().then((res) => {
      this.loginTtile = res.data.msg;
      window.document.title = res.data.msg;
    });
    getLoginCopyRight().then((res) => {
      this.copyRight = res.data.msg.split("-");
    });
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    btnText() {
      if (this.smallBtnLoading) {
        return "";
      } else {
        if (this.ifShowCount) {
          return this.count;
        } else {
          return "发送验证码";
        }
      }
    },
  },
  methods: {
    // validatePassword(rule, value, callback) {
    //   if (!value) {
    //     callback(new Error("请输入密码"));
    //   }
    //   if (
    //     !/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(
    //       value
    //     )
    //   ) {
    //     callback(new Error("密码格式必须为6~10位数字加字母，且不能是纯数字"));
    //   } else {
    //     callback();
    //   }
    // },
    jumpToResetPassword() {
      this.$router.push({
        name: "resetPassword",
        params: {
          usertype: this.form.getFieldValue("usertype"),
        },
      });
    },
    // sendVerifyCode() {
    //   this.form.validateFields(["mobile"], (err, values) => {
    //     if (!err) {
    //       this.smallBtnLoading = true;
    //       const usertype = this.form.getFieldValue("usertype");
    //       const request = usertype == "1" ? loginCodeP : loginCodeB;
    //       request({
    //         mobile: values.mobile,
    //         platform: "FAN"
    //       }).then(d => {
    //         this.smallBtnLoading = false;
    //         if (d.status == 1) {
    //           this.ifShowCount = true;
    //           this.timer = setInterval(() => {
    //             if (this.count <= 1) {
    //               this.count = 60;
    //               this.ifShowCount = false;
    //               clearInterval(this.timer);
    //               return;
    //             }
    //             this.count--;
    //           }, 1000);
    //         }
    //       });
    //     }
    //   });
    // },
    ...mapMutations([
      "mutationsChangeArray",
      "mutationsChangeName",
      "mutationsChangeId",
    ]),
    handleSubmit() {
      this.loginBtn = true;
      this.$refs.formLogin.validate((valid) => {
        if (valid) {
          // debugger
          // let params = {};
          // params.name = values.username;
          // params.password = values.password;
          // loginApi(params).then(res => {
          //   if (res.status == 200) {
          //     localStorage.setItem("isLogin", 'true');
          //     this.$router.push({ name: "channelData" });
          //   } else {
          //     this.$message.error(res.message);
          //   }
          // });
          // axios
          // .post("api/auth/login",this.form)
          if (this.checked) {
            localStorage.setItem(
              "account",
              JSON.stringify({
                username: this.form.loginAccount,
                password: this.form.password,
                ifRemember: true,
              })
            );
          } else {
            localStorage.setItem(
              "account",
              JSON.stringify({
                username: this.form.loginAccount,
                password: this.form.password,
                ifRemember: false,
              })
            );
          }
          // axios
          //   .post("http://192.168.2.101:8088/api/auth/login", this.form)
          loginApi(this.form).then((res) => {
            // debugger
            if (res.data) {
              console.log("1");
              localStorage.removeItem("islink");
              localStorage.setItem("accessToken", res.data.data.token);
              localStorage.setItem("userName", res.data.data.loginAccount);

              // let data = {};
              // axios
              //   .post("http://192.168.2.101:8088/api/auth/logout", data, {
              //     headers: {
              //       Authorization: localStorage.getItem("accessToken"),
              //     },
              //   })
              //   .then((res) => {
              //     if (res.data) {
              //       console.log("1");
              //     }
              //   });
              // axios
              //   .get("http://192.168.2.101:8088/api/auth/getMenu", {
              //     headers: {
              //       Authorization: localStorage.getItem("accessToken"),
              //     },
              //   })
              // 获取角色列表
              let sysRoles = res.data.data.roleList;
              localStorage.setItem("sysRoles", JSON.stringify(sysRoles));
              // 判断角色是否有中关村界面的权限
              let zgcRoleArr = ["中关村", "zgc"];
              let hasRoles = sysRoles.find((item) => {
                if (
                  zgcRoleArr.find((jtem) => {
                    if (item.roleName.indexOf(jtem) > -1) {
                      return jtem;
                    }
                  })
                ) {
                  return item;
                }
              });
              getMenu().then((res) => {
                if (res.data.code === 0) {
                  // console.log("1");
                  // res.data.data[0].component = '/homeMap/index'
                  if (res.data.data.length > 0) {
                    let index = res.data.data.findIndex(
                      (item) => item.name === "项目概览"
                    );
                    let account = JSON.parse(
                      localStorage.getItem("account") || "{}"
                    );
                    if (
                      index > -1 &&
                      account &&
                      (account.username.indexOf("zgc") > -1 ||
                        account.username.indexOf("baidu") > -1 ||
                        hasRoles)
                    ) {
                      res.data.data[index].component = "/projectOverall/zgc";
                    }
                    let tempMenu = JSON.parse(JSON.stringify(res.data.data));
                    let aa = JSON.stringify(res.data.data);
                    console.log("aa", aa);
                    window.localStorage.setItem(
                      "MENU",
                      JSON.stringify(res.data.data)
                    );
                    const menu = utils.formatRoutes(res.data.data);
                    let temp = [
                      {
                        path: "/",
                        name: "layout",
                        redirect: "home",
                        component: () => import("@/layout"),
                        meta: { title: "首页" },
                        children: [],
                      },
                    ];
                    console.log("menu", menu);
                    temp[0].children = menu;

                    // window.localStorage.setItem("isLogin", '1')
                    // this.$router.options.routes[0].children = menu;
                    // console.log(this.$router.options.routes);

                    // this.$router.addRoutes(this.$router.options.routes);
                    // console.log(this.$router.options.routes);

                    this.$router.addRoutes(temp);
                    console.log("temp", temp);

                    getProjects().then((res1) => {
                      if (res1.data.code === 0) {
                        // this.dataOption1 = res1.data.data;
                        // this.projectName = res1.data.data[0].projectName;
                        if (res1.data.data.length === 0) {
                          this.$message.error("未配置项目");
                          return;
                        } else {
                          this.mutationsChangeId(res1.data.data[0].projectId);
                          this.mutationsChangeName(
                            res1.data.data[0].projectName
                          );
                          this.mutationsChangeArray(res1.data.data);
                          setProject(res1.data.data[0].projectId).then(
                            (res2) => {
                              if (res2.data.code === 0) {
                                // debugger
                                if (tempMenu[0].children.length === 0) {
                                  this.$router.push({
                                    name: tempMenu[0].menuId,
                                  });
                                } else {
                                  this.$router.push({
                                    name: tempMenu[0].children[0].menuId,
                                  });
                                }
                              }
                            }
                          );
                        }
                      }
                    });
                  } else {
                    this.$message.error("该用户未设置菜单");
                  }
                }
              });
              // .catch((error) => {
              //   this.$message.error("系统未知错误");
              //   console.log(error);
              // });
            } else {
              this.$message.error(res.data.msg);
            }
          });
          // .catch((error) => {
          //   // debugger
          //   this.$message.error("系统未知错误");
          //   console.log(error);
          // });
        }
        this.loginBtn = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.bg {
  width: 100%;
  height: 100%;
  background: url("~@/assets/底色2b.png");
  background-size: 100% 100%;
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  & > .center {
    position: absolute;
    top: 230px;
    right: 101px;
    // display: flex;
    // align-items: center;
    // .img {
    //   width: 725px;
    //   height: 543px;
    // }
    .form-wrap {
      width: 516px;
      height: 490px;
      padding: 45px 90px 90px 90px;
      // background-color: #ffffff;
      background: rgba(4, 23, 76, 0.7);
      // border: 2px solid #3F6DF8;
      border-width: 2px;
      border-style: solid;
      border-color: rgba(63, 109, 248, 0.5);
      // opacity: 0.5;
      .title {
        color: white;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 44px;
        opacity: 0.8;
      }
      .inputs {
        margin-top: 15px;
      }
      .login-button {
        width: 100%;
        height: 50px;
      }
    }
  }
  .forget {
    position: absolute;
    right: 0;
    bottom: 0;
    span {
      color: #999999;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #0bb4ab;
      }
    }
  }
}
.copyright {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
  text-align: center;
  // font-size: 12px;
  // color: #aaa;
  // span {
  //   margin-left: 5px;
  // }
}
</style>
<style lang="less">
.login-route {
  .ant-tabs-tab {
    font-size: 16px;
  }
  .remember-wrap {
    .ant-form-item-control {
      line-height: inherit;
    }
  }
  .validate-code {
    position: relative;
    input {
      padding-right: 110px;
    }
    .code-wrap {
      position: absolute;
      right: 4px;
      top: -11px;
      .ant-btn {
        width: 100px;
      }
    }
  }
}
</style>
<style scoped>
.input >>> input {
  background: transparent !important;
  border: 1px solid #8ca8fe !important;
  color: white;
}
.el-checkbox >>> .el-checkbox__inner {
  background: transparent;
}
.el-button {
  border: none;
  font-size: 18px;
}
</style>
